import { Box, Grid, Typography } from "@mui/material";
import { isEqual } from "lodash";
import { ReactComponent as CubesSolidIcon } from "../../assets/faIcons/cubes-solid.svg";
import {
  thunks,
  updateProducts,
} from "../../globalStore/slices/organization/organizationSlice";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ActionButtons from "../../components/Buttons/ActionButtons";
import AddIcon from '@mui/icons-material/Add';
import ConfirmationModalContent from "../../components/Modals/ConfirmationModalContent";
import ConnectPageBuilderModal from "./ConnectPageBuilderModal";
import CreateBatch from "./CreateBatch/CreateBatch";
import EditIcon from '@mui/icons-material/Edit';
import EditProduct from "./EditProduct";
import HoverIconButton from "../../components/ReusedComponents/HoverIconButton";
import MaterialConfirmationModal from "../../components/Modals/MaterialConfirmationModal";
import MaterialUiDataGridPremium from "../../components/Tables/DataGridPremium/MaterialUiDataGridPremium";
import ModalDialog from "../../components/Modals/ModalDialog/ModalDialog";
import SmartphoneIcon from '@mui/icons-material/Smartphone';

export default function Products(props) {
  const {
    apiUrl,
    organizationId,
    timeZone,
    token,
    userAvailableActions,
  } = props;
  const { facilities, organization, products } = useSelector(
    (state) => state.organization,
    isEqual
  );

  const [state, setState] = useState({
    assetTypes: [],
    confirmationText: "",
    error: false,
    facilities: {},
    isCreate: false,
    isLoading: false,
    itemLevelDataElements: [],
    modal: "",
    modalShow: false,
    modalTitle: "",
    productHash: {},
    products: [],
    productTypes: [],
    // Redirect is set here in the instance someone gets redirect to this page with a product loaded up in reactRouter
    redirect: false,
    selectedProduct: {},
    selectedProducts: {},
  });

  const dispatchGlobal = useDispatch();
  const location = useLocation();

  const { retrieveBatches: retrieveBatchesThunk } = thunks.batches;
  const { retrieveProducts: retrieveProductsThunk } = thunks.products;

  // onSuccess function retrieves products, patch/diff with redux store, and resets location state
  const retrieveProducts = async () => {
    location.state = {};
    return await dispatchGlobal(retrieveProductsThunk()).then((res) => {
      return res.payload?.products || {};
    });
  };

  // wrapping init up in useCallback to prevent useEffect loop
  const init = useCallback(
    (isMounted) => {
      if (!isMounted) {
        dispatchGlobal(retrieveProductsThunk());
      }
    },
    [dispatchGlobal, retrieveProductsThunk]
  );

  const mounted = useRef(false);
  // first render useEffect, initialization and redirect logic
  useEffect(() => {
    const { propertiesMap = {}, assetTypes = [] } = organization;
    const { productTypes = [], itemLevelDataElements = [] } = propertiesMap;

    const { state: routerState = {} } = props.location;
    const {
      isCreate = false,
      modal = "",
      modalShow = false,
      modalTitle = "",
      product,
      redirect = false,
    } = routerState;

    setState((s) => {
      return {
        ...s,
        assetTypes,
        itemLevelDataElements,
        products: Object.keys(products).map((k) => products[k]),
        productHash: products,
        productTypes: productTypes.filter((t) => t !== null),
        facilities: facilities,
      };
    });
    if (redirect && product) {
      setState((s) => {
        return {
          ...s,
          isCreate: isCreate,
          selectedProduct: product,
          modalShow: true,
          modal: "Edit Product",
          modalTitle: isCreate ? "Create Product" : "Edit Product",
          redirect: true,
        };
      });
    } else if (modalShow && modalTitle === "Create Product") {
      setState((s) => {
        return {
          ...s,
          modal,
          modalShow,
          modalTitle,
          redirect: true,
          isCreate,
        };
      });
    }

    init(mounted.current);

    mounted.current = true;

    // This controls the quickLinks and modal behavior
    return props.history.listen((location) => {
      setState((s) => {
        return { ...s, ...location.state };
      });
    });
  }, [props, facilities, organization, products, init]);

  const handleDelete = (override = false) => {
    const { selectedProduct = {} } = state;
    const { productId = "" } = selectedProduct;

    fetch(`${apiUrl}products/${productId}${override ? "?override=true" : ""}`, {
      method: "DELETE",
      headers: {
        "auth-token": token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          if (override) {
            // TODO: This needs to be removed at some point. A batch can have more than one product associated with it.
            // Thus, a batch does not need to be deleted if a product is deleted. Because of this we are only drilling one
            // level down on the topologyMap.
            retrieveProducts().then((updatedProductsMap) => {
              dispatchGlobal(retrieveBatchesThunk()).then((res) => {
                if (res?.payload?.batches) {
                  const batches = Object.keys(res.payload.batches).map(
                    (k) => res.payload.batches[k]
                  );

                  handleBatchDelete(batches, productId, updatedProductsMap);
                }
              });
            });
          } else {
            handleConfirmationModal("Product Successfully Deleted");
            retrieveProducts();
          }
        } else if (res.error === "There are Assets with this Product") {
          setState((s) => {
            return {
              ...s,
              modal: "Delete Batch",
              modalTitle: "Batch Deletion",
            };
          });
        }
      })
      .catch(() => {
        handleConfirmationModal(
          "Unable to delete product, please refresh and try again.",
          true,
          5000
        );
      });
  };

  const handleBatchDelete = (batches, productId, updatedProductsMap) => {
    const batchesToDelete = [];
    batches.forEach((element) => {
      const batch = element || {};
      const { topologiesList = [] } = batch;
      let isDeletionValid = true;
      let isProductIdMatch = false;

      topologiesList.forEach((topologyMap) => {
        // Here we verify that we are not deleting a batch associated with an active product
        if (updatedProductsMap[topologyMap.productId] !== undefined) {
          isDeletionValid = false;
        }
        // We also verify that we are deleting a batch associated with the selected productId
        if (topologyMap.productId === productId) {
          isProductIdMatch = true;
        }
      });

      if (isDeletionValid && isProductIdMatch) {
        batchesToDelete.push(element.batchId);
      }
    });
    if (batchesToDelete.length > 0) {
      batchesToDelete.forEach((batchId) => {
        fetch(`${apiUrl}batches/${batchId}`, {
          method: "DELETE",
          headers: {
            "auth-token": token,
          },
        })
          .then(() => {
            return null;
          })
          .catch(() => {
            console.log("Batch Deleted Failed");
          });
      });
    }

    handleConfirmationModal("Product Successfully Deleted");
  };

  const handleConfirmationModal = (confirmationText, error = false) => {
    setState((s) => {
      return {
        ...s,
        confirmationModalShow: true,
        confirmationText,
        modalShow: false,
        error,
      };
    });
  };

  const {
    assetTypes,
    confirmationModalShow,
    confirmationText,
    error,
    isCreate,
    itemLevelDataElements,
    modal,
    modalTitle,
    productTypes,
    redirect,
    selectedProduct,
  } = state;

  const modalClose = () => {
    setState((s) => {
      return {
        ...s,
        modalShow: false,
        isCreate: false,
        selectedProduct: [],
        confirmationModalShow: false,
      };
    });
  };

  const tableColumns = [
    {
      field: "name",
      flex: 1,
      headerName: "Product Name",
      id: "name",
      renderCell: (data) => {
        const { row } = data;
        if(data.rowNode.type === "group") {
          return data.value;
        }
        return (
          <div
            className="pointer"
            onClick={() => {
              setState((s) => {
                return {
                  ...s,
                  selectedProduct: row,
                  modalShow: true,
                  modal: "View Product",
                  modalTitle: "Product Information",
                };
              });
            }}
          >
            {row.name ? row.name : ""}
          </div>
        );
      },
      valueGetter: (value, row) => row.name || "",
      groupingValueGetter: (value, row) => row.name || null,
    },
    {
      field: "productType",
      flex: 1,
      headerName: "Product Category",
      id: "productType",
      renderCell: (data) => {
        const { row } = data;
        if(data.rowNode.type === "group") {
          return data.value;
        };
        const { productType = "" } = row;
        const productCat =
          row.propertiesMap && row.propertiesMap.productType
            ? row.propertiesMap.productType
            : "";
        return (
          <div
            className="pointer"
            onClick={() => {
              setState((s) => {
                return {
                  ...s,
                  selectedProduct: row,
                  modalShow: true,
                  modal: "View Product",
                  modalTitle: "Product Information",
                };
              });
            }}
          >
            {productType ? productType : productCat ? productCat : ""}
          </div>
        );
      },
      valueGetter: (value, row) => {
        const { productType = "" } = row;
        const productCat =
          row.propertiesMap && row.propertiesMap.productType
            ? row.propertiesMap.productType
            : "";
        return productType || productCat || "";
      },
      groupingValueGetter: (value, row) => {
        const { productType = "" } = row;
        const productCat =
          row.propertiesMap && row.propertiesMap.productType
            ? row.propertiesMap.productType
            : null;
        return productType || productCat || null;
      },
    },
    {
      field: "tag",
      flex: 1,
      headerName: "Product Code",
      id: "tag",
      renderCell: (data) => {
        const { row } = data;
        const { tag = "" } = row;
        if(data.rowNode.type === "group") {
          return data.value;
        }

        return (
          <div
            className="pointer"
            onClick={() => {
              setState((s) => {
                return {
                  ...s,
                  selectedProduct: row,
                  modalShow: true,
                  modal: "View Product",
                  modalTitle: "Product Information",
                };
              });
            }}
          >
            {tag}
          </div>
        );
      },
      valueGetter: (value, row) => row.tag || "",
      groupingValueGetter: (value, row) => row.tag || null,
    },
    {
      field: "actions",
      flex: 1,
      groupable: false,
      headerName: "Actions",
      id: "actions",
      renderCell: (data) => {
        const { row } = data;
        if(data.rowNode.type === "group") return null;
        const buttonIcons = [];

        if (userAvailableActions.includes("Edit Products")) {
          buttonIcons.push({
            icon: <EditIcon/>,
            title: "Edit",
            action: () => {
              setState((s) => {
                return {
                  ...s,
                  selectedProduct: row,
                  modalShow: true,
                  modal: "Edit Product",
                  modalTitle: "Edit Product",
                };
              });
            },
          });

          buttonIcons.push({
            icon: <CubesSolidIcon/>,
            title: "Create Batch",
            action: () => {
              setState((s) => {
                return {
                  ...s,
                  selectedProduct: row,
                  modalShow: true,
                  modal: "Create Batch",
                  modalTitle: "Create Batch",
                };
              });
            },
          });

          buttonIcons.push({
            icon: <SmartphoneIcon/>,
            title: "Connect Page Builder",
            action: () => {
              setState((prevState) => ({
                ...prevState,
                modal: "Connect Page Builder",
                modalShow: true,
                modalTitle: "Connect Page Builder",
                selectedProduct: row,
              }));
            },
          });

          return <ActionButtons content={buttonIcons} />;
        }
      },
    },
  ];

  // This controls which modal the <CenterModal /> displays
  const switchModal = (modal) => {
    switch (modal) {
      case "Connect Page Builder":
        return <ConnectPageBuilderModal setState={setState} state={state} />;
      case "Create Batch":
        return (
          <CreateBatch
            apiUrl={apiUrl}
            assetTypes={assetTypes}
            facilities={facilities}
            hasFormFields={true}
            isLoading={state.isLoading}
            itemLevelDataElements={
              selectedProduct.propertiesMap?.itemLevelDataElements || []
            }
            onHide={modalClose}
            onSuccess={() => {
              handleConfirmationModal("Batch Successfully Created");
              retrieveProducts();
            }}
            organizationId={organizationId}
            notificationModal={handleConfirmationModal}
            pcaId={organization.pcaId || ""}
            pcaSettings={organization.propertiesMap?.pcaSettings || {}}
            selectedProduct={selectedProduct}
            setState={setState}
            timeZone={timeZone}
            token={token}
          />
        );
      case "Delete Batch":
        return (
          <ConfirmationModalContent
            content={`There might be batches associated with ${selectedProduct.name}. (All batches and data will be deleted) This action cannot be undone`}
            handleSubmit={() => {
              handleDelete(true);
            }}
            handleCancel={() => {
              modalClose();
            }}
          />
        );
      case "Delete Product":
        return (
          <ConfirmationModalContent
            content={`Are you sure you want to delete ${selectedProduct.name}? This action cannot be
                undone.`}
            handleSubmit={() => {
              handleDelete();
            }}
            handleCancel={() => {
              modalClose();
            }}
          />
        );
      case "Edit Product":
        return (
          <EditProduct
            apiUrl={apiUrl}
            assetTypes={assetTypes}
            confirmationModal={handleConfirmationModal}
            dispatchGlobal={dispatchGlobal}
            hasFormFields={true}
            isCreate={isCreate}
            itemLevelDataElements={itemLevelDataElements}
            modalClose={modalClose}
            onSuccess={() => {
              modalClose();
            }}
            organizationId={organizationId}
            productTypes={productTypes}
            updateProducts={updateProducts}
            redirect={redirect}
            selectedProduct={selectedProduct}
            token={token}
          />
        );
      case "View Product":
        return (
          <EditProduct
            apiUrl={apiUrl}
            assetTypes={assetTypes}
            confirmationModal={handleConfirmationModal}
            isCreate={isCreate}
            itemLevelDataElements={itemLevelDataElements}
            onSuccess={retrieveProducts}
            productTypes={productTypes}
            readOnly={true}
            selectedProduct={selectedProduct}
            token={token}
          />
        );
      default:
        return;
    }
  };

  function toolBarButtons() {
    return (
      <Grid
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "2rem",
        }}
      >
        {userAvailableActions.includes("Create Products") ? (
          <HoverIconButton
            cypressId="products-btn-create-product"
            handleClick={() => {
              setState((s) => {
                return {
                  ...s,
                  isCreate: true,
                  modal: "Edit Product",
                  modalShow: true,
                  modalTitle: "Create Product",
                };
              });
            }}
            icon={<AddIcon/>}
            iconDirection="right"
            text="Create Product"
          />
        ) : null}
      </Grid>
    );
  }

  return (
    <Box ml={1}>
      <Grid
        container
        sx={{
          margin: "0 auto",
          width: "84vw",
        }}
      >
        <ModalDialog
          content={switchModal(modal)}
          handleClose={modalClose}
          open={state.modalShow}
          title={modalTitle}
        />
        <MaterialConfirmationModal
          closeModal={modalClose}
          content={confirmationText}
          modalOpen={confirmationModalShow}
          severity={error ? "error" : "success"}
          variant="filled"
        />

        {/* Header */}
        <Grid item xs={12}>
          <Box mt={2} mb={2} textAlign="left">
            <Typography component="h1" variant="h4">
              Products
            </Typography>
          </Box>
        </Grid>

        {/* Table */}
        <Grid item xs={12}>
          <MaterialUiDataGridPremium
            checkboxSelection={false}
            columns={tableColumns}
            disableCsvExportOption={false}
            exportFileName={"Products"}
            getRowId={(row) => row.productId}
            loading={false}
            needsCustomToolbar={true}
            rowCount={state.products.length}
            rows={state.products}
            toolBarButtons={toolBarButtons}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
