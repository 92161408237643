import {
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { checkSelectedAssets } from "./utils";
import { exportQRSpreadsheet } from "./xlsx/xlsx";
import { getAssetsById } from "./api";
import { Grid, Paper } from "@mui/material";
import { isEmpty, isEqual } from "lodash";
import { Redirect } from "react-router-dom";
import { thunks } from "../../../globalStore/slices/inventory/inventorySlice";
import { useDispatch, useSelector } from "react-redux";
import AssignmentReturnedIcon from "@mui/icons-material/AssignmentReturned";
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import HoverIconButton from "../../../components/ReusedComponents/HoverIconButton";
import InventoryMapFilter from "./InventoryMapFilter";
import InventoryStatusMap from "./Map/InventoryStatusMap";
import LaptopIcon from "@mui/icons-material/Laptop";
import Loading from "../../../components/Loading/Loading";
import MapIcon from "@mui/icons-material/Map";
import MapTable from "./Map/MapTable";
import ModalDialog from "../../../components/Modals/ModalDialog/ModalDialog";
import MuiAlert from "@mui/material/Alert";
import QrCodeIcon from "@mui/icons-material/QrCode";
import Snackbar from "@mui/material/Snackbar";
import TableChartIcon from "@mui/icons-material/TableChart";

// Components are super heavy, which is why we are using lazy/Suspense
const AssignDevice = lazy(() =>
  import("../../assets/assignDevice/AssignDevice")
);
const ImportAssets = lazy(() =>
  import("../../assets/ImportAssets/ImportAssets")
);
const Table = lazy(() => import("./Table/Table"));

export default function InventoryStatus(props) {
  const classes2 = {
    button: {
      marginLeft: "1rem",
      marginBottom: ".25rem",
    },
    buttonContainer: {
      textAlign: "right",
    },
    paper: {
      minHeight: "68vh",
      padding: "10px",
      textAlign: "center",
    },
    map: (theme) => ({
      padding: theme.spacing(2),
      minHeight: "62vh",
      maxHeight: "62vh",
    }),
    mapTable: (theme) => ({
      padding: theme.spacing(2),
      minHeight: "62vh",
      maxHeight: "62vh",
      overflow: "auto",
    }),
  }

  const dispatchGlobal = useDispatch();
  const saveFilterSettings = thunks.assets.saveFilterSettings;
  const showLastEvents = thunks.assets.showLastEvents;

  const {
    apiUrl,
    appUserType,
    organizationId,
    timeZone,
    token,
    userId,
    usersConsoleRole,
  } = props;

  // OK to destructure, as no changes to org data takes place in this page
  const {
    organization,
    zones,
    facilities,
    customs,
    devices,
    classifications,
    usersMap,
    products,
  } = useSelector((state) => state.organization, isEqual);

  let inventoryStatusClassifications = {};

  Object.keys(classifications).forEach((key) => {
    let classification = classifications[key];
    if (classification.assetMode === "Inventory") {
      inventoryStatusClassifications = {
        ...inventoryStatusClassifications,
        [key]: classification,
      };
    }
  });

  const assetTypes =
    organization && organization.assetTypes
      ? [...organization.assetTypes].sort()
      : [];

  const eventTypes =
    organization && organization.eventTypesMap
      ? Object.keys(organization.eventTypesMap).sort()
      : [];

  const facilityArray = facilities
    ? Object.values(facilities).sort((a, b) => {
        return (a.name || "").localeCompare(b.name || "");
      })
    : [];

  const allDevices = Object.keys(devices).map((k) => devices[k]);
  const availableDevices = allDevices.filter(
    (item) => item.device.status !== "assigned"
  );

  const assetsStore = useSelector((state) => state.assets, isEqual);

  const { filters: initFilters = {} } = assetsStore;

  const [state, setState] = useState({
    allDevices,
    availableDevices,
    availableZones: Object.keys(zones).map((zone) => {
      // TODO: Is this how we are going to handle the differences between
      // pZones and tZones?
      return {
        internalZoneType: zones[zone].internalZoneType || null,
        label: zones[zone].name,
        value: zones[zone].zoneId,
      };
    }),
    count: assetsStore.count,
    filters: {
      ...initFilters,
      startDate: new Date(),
      endDate: new Date(),
    },
    lists: {
      assetTypes: assetTypes,
      eventTypes: eventTypes,
      eventTypesMap: organization.eventTypesMap || {},
      facilityArray: facilityArray,
      assetCategoriesList:
        organization.assetCategoriesList || organization.assetCategories || [],
    },
    mapTableData: [],
    mounted: true,
    page: 0,
    parentAssetIdTagMap: {},
    selectedAssets: {},
  });

  const [loading, setLoading] = useState(false);

  const [modal, setModal] = useState({
    modalShow: false,
    title: "",
    content: {},
  });

  const modalClose = () =>
    setModal({ modalShow: false, title: "", content: "" });

  const [dialog, setDialog] = useState({
    dialogShow: false,
  });

  const [isRedirect, setIsRedirect] = useState(false);

  const [confirmationModalShow, setConfirmationModalShow] = useState({
    modalShow: false,
    text: "",
    error: false,
  });

  const [importAssetsModalTitle, setImportAssetsModalTitle] =
    useState("Import Inventory");

  const isMountedRef = useRef(false);

  // upon first mount, this function will check for updates in the store by dispatching the showLastEvents thunk
  const init = useCallback(
    (isMounted) => {
      if (!isMounted) {
        dispatchGlobal(
          showLastEvents({ filters: state.filters, page: state.page })
        ).then((res) => {
          isMountedRef.current = true;
          setState((prevState) => ({
            ...prevState,
            assets: res.payload.assets,
            parentAssetIdTagMap: res.payload.parentAssetIdTagMap,
          }));
        });
      }
    },
    [dispatchGlobal, showLastEvents, state]
  );

  // intializing effect, this is for checking for updated asset status on first mount using initial filters. Using a ref to ensure it only fires on first mount.
  useEffect(() => {
    init(isMountedRef.current);
  }, [init]);

  // this effect fires whenever filters are updated (either in the filter menu or by changing the page or row limit).
  // it checks the isMountedRef to prevent firing on first mount, sets loading to true, and then dispatches the showLastEvents thunk to our store to searchAssets and update state
  // it then takes the return from our showLastEvents thunk and updates the local state
  useEffect(() => {
    if (isMountedRef.current) {
      setLoading(true);
      dispatchGlobal(
        showLastEvents({
          filters: state.filters,
          page: state.page,
          useFilters: false,
        })
      ).then((results) => {
        setLoading(false);
        setState((prevState) => {
          return {
            ...prevState,
            assets: results.payload.assets,
            count: results.payload.count,
            page: Math.floor(state.filters.start / state.filters.limit),
            parentAssetIdTagMap: results.payload.parentAssetIdTagMap,
            // reset map table data
            mapTableData: [],
          };
        });
      });
    }
  }, [dispatchGlobal, showLastEvents]);

  const handleSave = async (filters) => {
    // Removes the start from saving to the users settings
    delete filters.start;

    return await dispatchGlobal(saveFilterSettings(filters));
  };

  function handleRowSelect(selectedRowIds) {
    let updatedSelectedAssets = {};
    const assetsList = state.assets;

    // go through the selectedRowIds and find the asset that matches the id. If that asset exists, add it to the updatedSelectedAssets object
    for (let rowId of selectedRowIds) {
      for (let asset of assetsList) {
        if (asset.assetId === rowId) {
          updatedSelectedAssets = {
            ...updatedSelectedAssets,
            [asset.assetId]: asset,
          };
        }
      }
    }

    setState((s) => {
      return {
        ...s,
        selectedAssets: updatedSelectedAssets,
      };
    });
  }

  async function checkDevices() {
    let isValid = false;
    let assets;
    let assetIds = [
      ...new Set(
        Object.keys(state.selectedAssets).map((asset) => {
          return asset;
        })
      ),
    ];

    // Retrieve up to date asset information
    assets = await getAssetsById(
      { apiUrl, token, organizationId },
      assetIds,
      1000
    );

    // Checks to verify selected asset(s) do not currently have a device associated
    const hasParentId = assets.assets.find((element) => element.parentId);

    // Checks to verify they actually have enough devices for the amount of selected assets
    const hasEnoughDevices =
      state.availableDevices.length < Object.keys(state.selectedAssets).length;

    if (hasEnoughDevices) {
      setConfirmationModalShow({
        modalShow: true,
        text: "You have selected more assets than there are available devices. Please adjust your selections.",
        error: true,
      });
    } else if (hasParentId) {
      setConfirmationModalShow({
        modalShow: true,
        text: "You have selected one or more assets that already has a device assigned to it.",
        error: true,
      });
    } else isValid = true;

    if (isValid) {
      setModal({
        modalShow: true,
        title: "Assign a Tracker",
        content: (
          <Suspense fallback={<Loading />}>
            <AssignDevice
              allDevices={state.allDevices}
              apiUrl={apiUrl}
              availableDevices={state.availableDevices}
              organizationId={organizationId}
              setConfirmationModalShow={(value) => {
                setConfirmationModalShow(value);
                setTimeout(() => {
                  setConfirmationModalShow({
                    modalShow: false,
                    text: "",
                    error: false,
                  });
                }, 1000);
              }}
              selectedAssets={state.selectedAssets}
              setModal={setModal}
              timezone={timeZone}
              token={token}
            />
          </Suspense>
        ),
      });
    }
  }

  function toolBarButtons() {
    return (
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "2rem",
        }}
      >
        <HoverIconButton
          icon={<MapIcon />}
          iconDirection="right"
          handleClick={() => {
            setState((s) => {
              return {
                ...s,
                displayMap: !s.displayMap,
              };
            });
          }}
          text={state.displayMap ? `View Table` : `View Map`}
        />

        {/* Lite Users are unable to manipulate data */}
        {usersConsoleRole === "Lite" || state.displayMap ? null : (
          <>
            {/* Assign a Tracker */}
            <HoverIconButton
              disabled={isEmpty(state.selectedAssets)}
              text="Assign a Tracker"
              icon={<LaptopIcon />}
              iconDirection="right"
              handleClick={() => {
                if (!isEmpty(state.selectedAssets)) {
                  checkDevices();
                }
              }}
            />

            {/* Generate CQRs Button */}
            <HoverIconButton
              text="Generate CQRs"
              icon={<QrCodeIcon />}
              iconDirection="right"
              disabled={isEmpty(state.selectedAssets)}
              handleClick={() => {
                if (!isEmpty(state.selectedAssets)) {
                  checkSelectedAssets(
                    { organizationId, apiUrl, token },
                    state
                  ).then((results) => {
                    const { faultyContent = [], isError = true } = results;
                    if (!isError) {
                      setIsRedirect(true);
                    } else {
                      setConfirmationModalShow({
                        modalShow: true,
                        text: `The Asset Tag(s)${faultyContent.map((item) => {
                          return ` ${item}`;
                        })} are shown as still being in circulation, therefore the CQR cannot be regenerated. If you need to reprint a CQR for an asset, please contact your organizations Admin User.`,
                        error: true,
                      });
                    }
                  });
                }
              }}
            />

            {/* Export QR urls Button */}
            <HoverIconButton
              text="Export QR Urls"
              icon={<ContentPasteGoIcon />}
              iconDirection="right"
              disabled={isEmpty(state.selectedAssets)}
              handleClick={() => {
                exportQRSpreadsheet(
                  Object.values(state.selectedAssets),
                  token,
                  "Inv_Status"
                );
              }}
            />

            {/* Import Inventory Button */}
            <HoverIconButton
              text="Import"
              icon={<AssignmentReturnedIcon />}
              iconDirection="right"
              handleClick={() => {
                setDialog({ dialogShow: true });
              }}
              disabled={false}
            />
          </>
        )}
      </Grid>
    );
  }

  return (
    <Grid
      className="inventory-status"
      container
      style={{
        marginTop: "1rem",
        margin: "0 auto",
        width: "84vw",
      }}
    >
      {isRedirect ? (
        <Redirect
          to={{
            pathname: "/generateCQR/",
            state: { selectedAssets: state.selectedAssets },
          }}
        />
      ) : (
        ""
      )}
      <ModalDialog
        handleClose={modalClose}
        open={modal.modalShow}
        title={modal.title}
        content={
          <Grid container>
            <Grid item xs={12}>
              {modal.content}
            </Grid>
          </Grid>
        }
      />
      <ModalDialog
        handleClose={() => {
          setDialog({
            dialogShow: false,
          });
        }}
        open={dialog.dialogShow}
        title={importAssetsModalTitle}
        content={
          <Suspense fallback={<Loading />}>
            <ImportAssets
              apiUrl={apiUrl}
              availableDevices={state.availableDevices}
              classifications={inventoryStatusClassifications || {}}
              facilities={facilities}
              isInventory={true}
              onHide={() => {
                setDialog({
                  dialogShow: false,
                });
                setImportAssetsModalTitle("Import Assets");
              }}
              onSuccess={() => {
                // force refetch via useEffect
                setState({
                  ...state,
                  filters: {
                    ...state.filters,
                  },
                });
              }}
              organization={organization}
              setConfirm={setConfirmationModalShow}
              setImportAssetsModalTitle={setImportAssetsModalTitle}
              token={token}
            />
          </Suspense>
        }
      />

      {/* My assumption is that we are going to need to change the material confirmation modal to match the snack bar but for now ill set it here */}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={confirmationModalShow.modalShow}
        onClose={() => {
          setConfirmationModalShow((prevState) => ({
            ...prevState,
            modalShow: false,
          }));
        }}
      >
        <MuiAlert
          onClose={() => {
            setConfirmationModalShow((prevState) => ({
              ...prevState,
              modalShow: false,
            }));
          }}
          severity={confirmationModalShow.error ? "error" : "success"}
        >
          {confirmationModalShow.text}
        </MuiAlert>
      </Snackbar>
      {state.displayMap ? (
        <Grid sx={classes2.buttonContainer}>
          <HoverIconButton
            handleClick={() => {
              setState((s) => {
                return {
                  ...s,
                  displayMap: !s.displayMap,
                };
              });
            }}
            icon={<TableChartIcon />}
            icondirection="right"
            text="View Status Table"
          />
        </Grid>
      ) : null}

      <Grid item xs={12}>
        {state.filters ? (
          <>
            {state.displayMap ? (
              <>
                <Paper sx={classes2.paper}>
                  <Grid>
                    <Grid item xs={12}>
                      <InventoryMapFilter
                        apiUrl={apiUrl}
                        isAssetAnalysis={true}
                        setState={setState}
                        state={state}
                        organizationId={organizationId}
                        token={token}
                      />
                    </Grid>
                  </Grid>

                  <p style={{ marginTop: "10px" }}>
                    Click marker, then "See Data" to view multiple assets from
                    the same location in the table below.
                  </p>
                  <Grid container spacing={3}>
                    <Grid item sm={6} xs={12} sx={classes2.map}>
                      <InventoryStatusMap
                        apiUrl={apiUrl}
                        classifications={inventoryStatusClassifications}
                        dispatchGlobal={dispatchGlobal}
                        eventTypesMap={state.lists.eventTypesMap}
                        facilities={facilities}
                        organizationId={organizationId}
                        setConfirm={setConfirmationModalShow}
                        setLoading={setLoading}
                        setState={setState}
                        showLastEvents={showLastEvents}
                        state={state}
                        timeZone={timeZone}
                        token={token}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12} sx={classes2.mapTable}>
                      <MapTable
                        mapTableData={state.mapTableData}
                        timeZone={state.filters.tz ? state.filters.tz : ""}
                        facilities={facilities}
                        zones={zones}
                        setState={setState}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </>
            ) : (
              <Suspense fallback={<Loading />}>
                <Table
                  allDevices={state.allDevices}
                  apiUrl={apiUrl}
                  appUserType={appUserType}
                  checkDevices={checkDevices}
                  classifications={inventoryStatusClassifications}
                  customs={customs}
                  defaultColumnOrder={state.filters?.defaultColumnOrder}
                  dispatchGlobal={dispatchGlobal}
                  facilities={facilities}
                  handleRowSelect={handleRowSelect}
                  handleSave={handleSave}
                  loading={loading}
                  organizationId={organizationId}
                  page={state.page}
                  parentAssetIdTagMap={state.parentAssetIdTagMap}
                  products={products}
                  setConfirm={setConfirmationModalShow}
                  setLoading={setLoading}
                  setModal={setModal}
                  setState={setState}
                  showLastEvents={showLastEvents}
                  state={state}
                  timeZone={timeZone}
                  token={token}
                  toolBarButtons={toolBarButtons}
                  userId={userId}
                  usersConsoleRole={usersConsoleRole}
                  usersMap={usersMap}
                  useSelector={useSelector}
                  zones={zones}
                />
              </Suspense>
            )}
          </>
        ) : null}
      </Grid>
    </Grid>
  );
}
