import { useEffect, useRef, useState } from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { isEqual } from "lodash";
import { searchAssets } from "../../../utils/API/Assets/Assets";
import { searchFacilityRadios } from "../../../utils/API/Radios/Radios";
import { useSelector } from "react-redux";
import CustomFilters from "../../../components/Selects/CustomsSelector/CustomFilters";
import FacilityImage from "../../facilities/FacilityOverview/FacilityImage";
import FacilityImageViewOptions from "../../facilities/FacilityOverview/components/FacilityImageViewOptions";
import MapViewLegend from "./MapViewLegend";
import MaterialUiButton from "../../../components/Buttons/MaterialUiButton/MaterialUiButton";
import NotificationModal from "../../../components/Modals/NotificationModal";
import SimpleSelect from "../../../components/Forms/FieldTypes/Select";
import SimpleTextField from "../../../components/Forms/FieldTypes/TextField";

function MapView(props) {
  const classes = {
    card: {
      alignItems: "center",
      backgroundImage: `linear-gradient(45deg, rgba(255,255,255,.2) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.2) 50%, rgba(255,255,255,.2) 75%, transparent 75%, transparent)`,
      display: "flex",
      height: "74vh",
      maxWidth: "80vw",
      position: "relative",
      width: "100%",
      backgroundColor: "grey",
      backgroundSize: "20px 20px",
    },
  };

  const { apiUrl, organizationId, token } = props;

  const { customs, facilities } = useSelector(
    (state) => state.organization,
    isEqual
  );

  const initialState = {
    assets: [],
    filters: {
      customs: [],
      tag: "",
      limit: 1000,
    },
    grid: { x: 0, y: 0 },
    radios: [],
    selectedFacility: { images: [] },
    selectedFacilityId: "",
    selectedFacilityImage: "",
    viewOptions: {
      showZones: true,
      showRadios: true,
    },
    zeroPoint: { disablePlunger: true },
    farPoint: { disablePlunger: true },
  }
  const [state, setState] = useState(initialState);

  const { selectedFacility, selectedFacilityId } = state;

  const initialAlertModalState = {
    alertModalColor: "error",
    modalShow: false,
    modalText: "",
  };
  const [alertModalState, setAlertModalState] = useState(
    initialAlertModalState
  );

  const [img, setImg] = useState({ height: 0, width: 0, isLoaded: false });
  const ref = useRef(null);

  const [loading, setLoading] = useState(false);

  const facilityOptions = Object.keys(facilities)
    .map((k) => facilities[k])
    .map((facility) => ({
      label: facility.name,
      value: facility.facilityId,
    }))
    //sort alphabetically
    .sort((a, b) => a.label.localeCompare(b.label));

  const handleImageLoad = (event) => {
    const height = ref.current.clientHeight;
    const width = event.target.clientWidth;
    setImg({ height, isLoaded: true, width });
  };

  useEffect(() => {
    
    const pngImageUrl = selectedFacility?.images?.find(
      (image) => image.imageType === "PNG"
    )?.url;

    //if no facility is selected, show alert modal
    if(selectedFacilityId && !pngImageUrl) {
    setAlertModalState({
        ...initialAlertModalState,
        modalShow: true,
        modalText: "No facility map image available for this facility",
    });
     //reset state to initial state
      setState(initialState);
      return;
    }
   
    const facilityImage = selectedFacility?.propertiesMap?.facilityImage;
     
      // make sure all 4 are present: facilityId, zeroPoint, farPoint, and grid.
      // if not, show alert modal
      if (selectedFacilityId && (!facilityImage || !facilityImage.zeroPoint || !facilityImage.farPoint || !facilityImage.grid)) {
        setAlertModalState({
        ...initialAlertModalState,
        modalShow: true,
        modalText: "Facility map image is missing grid, zero point, or far point",
      });

      setState(initialState)
      return;
    }

    async function retrieveImage() {

      if (pngImageUrl) {
        const selectedFacilityImage = pngImageUrl;
        const { grid, zeroPoint, farPoint } =
          selectedFacility?.propertiesMap?.facilityImage || {};

        const zones = selectedFacility.zones || [];

        // create a facilityZoneHashMap for easy lookup
        const facilityZoneHashMap = {};
        zones.forEach((zone) => {
          facilityZoneHashMap[zone.zoneId] = zone;
        });

        //create an array with just zoneIds
        const zoneIds = zones.map((zone) => zone.zoneId);

        try {
          const response = await searchFacilityRadios(props, zoneIds);

          //create a radioHashMap for easy lookup
          const radioHashMap = response.radios.reduce((acc, radio) => {
            acc[radio.radioId] = radio;
            return acc;
          }, {});

          setState((prevState) => {
            return {
              ...prevState,
              radios: response.radios,
              radioHashMap: radioHashMap,
            };
          });
          setAlertModalState(initialAlertModalState);
        } catch (error) {
          // Handle error and update state
          setAlertModalState({
            ...initialAlertModalState,
            modalShow: true,
            modalText: "Error fetching data",
          });
        } finally {
          // Stop loading
          setLoading(false);
        }

        // Update selected value in state
        setState((prevState) => {
          return {
            ...prevState,
            farPoint,
            grid,
            selectedFacility,
            selectedFacilityImage,
            zones,
            facilityZoneHashMap,
            filters: {
              customs: [],
              tag: "",
              limit: 1000,
            },
            zeroPoint,
          };
        });
      }
    }

    retrieveImage();
  }, [selectedFacility]);
  function handleFilterSubmit() {
    //if no facility is selected, show alert modal
      if (!state.selectedFacility.facilityId) {
      setAlertModalState({
        ...initialAlertModalState,
        modalShow: true,
        modalText: "Facility must be selected before filters can be set",
      });
      return;
    }
    searchAssets({ apiUrl, isInventory: true, token, organizationId, facilityId: state.selectedFacility.facilityId }, state).then((res) => {
      const { assets } = res;

      const updatedHashMap = { ...state.facilityZoneHashMap };

      //reset the color and opacity of all zones as they were originally
      state.selectedFacility.zones.forEach((zone) => {
        updatedHashMap[zone.zoneId] = zone;
      });

      assets.forEach((asset) => {
        const { zone = "" } = asset;
        const zoneObject = state.facilityZoneHashMap[zone.zoneId];
        const { zoneType = "" } = zoneObject || {};

        if (zoneType === "processing") {
          //update the color of the zone to purple and the opacity to 0.5
          updatedHashMap[zone.zoneId] = {
            ...zoneObject,
            color: "purple",
            count: updatedHashMap[zone.zoneId].count ? updatedHashMap[zone.zoneId].count + 1 : 1,
            opacity: 0.5,
          };
        } else if (zoneType === "target") {
          // update the color of the zone to orange and the opacity to 0.5
          updatedHashMap[zone.zoneId] = {
            ...zoneObject,
            count: updatedHashMap[zone.zoneId].count ? updatedHashMap[zone.zoneId].count + 1 : 1,
            color: "#03f8fc",
            opacity: 0.5,
          };
        }
      });

      setState((prevState) => {
        return {
          ...prevState,
          assets: assets,
          facilityZoneHashMap: updatedHashMap,
        };
      });
    });
  }

  const handleChange = (event) => {
    const newValue = event.target.value;
    setState((prevState) => {
      return {
        ...prevState,
        assets: [],
        selectedFacility: facilities[newValue],
        selectedFacilityId: newValue,
      };
    });

    // Call your API with the selected value
    // Example: apiCall(newValue);
  };

  if (loading) return <div>Loading...</div>;

  return (
    <>
      {/* Notification Modal */}
      <NotificationModal
        confirmationModalShow={alertModalState.modalShow}
        modalClose={() => {
          setAlertModalState(initialAlertModalState);
        }}
        confirmationText={alertModalState.modalText}
        color={alertModalState.alertModalColor}
      />
      <Grid container spacing={2}>
        {/* Top box */}
        <Grid item xs={12}>
          <Card
            sx={
              {
                // height: "12vh",
                // overflow: "auto",
              }
            }
          >
            {/* CardContent component has a default bottom padding of 24px. It gets overwritten here */}
            <CardContent sx={{ padding: "2px", "&:last-child": { pb: 0 } }}>
              <Grid item xs={12}>
                <Typography component="h2" variant="h6">
                  Map Legend
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <MapViewLegend state setState />
              </Grid>
              <Grid item xs={12}>
                <FacilityImageViewOptions
                  state={state}
                  setState={setState}
                  mainSx={{}}
                />
                {/* </Box>   */}
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Left Box (25% width) */}
        <Grid item xs={12} sm={3}>
          <Card
            sx={{
              height: "74vh",
              overflow: "auto",
            }}
          >
            <CardContent>
              {/* Select Facility */}
              <SimpleSelect
                // displayEmpty={true}
                getOptionLabel={(option) => option.label}
                label="Select Facility"
                // onChange={(e) => handleFacilityChange(e.target.value)}
                onChange={(e) => handleChange(e)}
                options={facilityOptions}
                size="large"
                value={state.selectedFacilityId}
                variant="outlined"
              />

              {/* Tag Id */}
              <SimpleTextField
                onChange={(event) =>
                  setState((prevState) => ({
                    ...prevState,
                    filters: {
                      ...prevState.filters,
                      tag: event.target.value,
                    },
                  }))
                }
                fullWidth
                size="large"
                value={state.filters.tag}
                label="Tag Id"
              />

              {/* Custom Filters */}
              <CustomFilters
                customs={customs}
                state={state}
                setState={setState}
                xsSize={12}
                cardSx={{}}
              />
            </CardContent>
          </Card>
          <MaterialUiButton
            label="Display"
            data-testid="Display Button"
            onClick={() => handleFilterSubmit()}
            fullWidth
            sx={{ marginBottom: "1rem", position: "relative", bottom: "38px" }}
          />
        </Grid>

        {/* Right Box (75% width) */}
        <Grid item xs={12} sm={9}>
          <Card raised sx={classes.card} ref={ref}>
            {state.selectedFacility ? (
              <FacilityImage
                facilityImage={state.selectedFacilityImage}
                handleImageLoad={handleImageLoad}
                img={img}
                state={state}
              />
            ) : null}
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default MapView;
