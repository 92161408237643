import { createAsyncThunk } from "@reduxjs/toolkit";
import { FlattenUserRoles } from "../../utils/helpers";
import moment from "moment-timezone";
import { isEmpty } from "lodash";

export const checkSession = createAsyncThunk(
  "user/checkSession",
  async (input, thunkAPI) => {
    const { getState, extra: apiService } = thunkAPI;

    const token =
      input || getState().user?.token || localStorage.getItem("token");

    if (!token) {
      return { success: false };
    } else {
      const data = await apiService.user
        .checkSession(token)
        .then((data) => {
          if (data.error) {
            // no session found
            return data;
          } else if (data.success && data.user) {
            const { user = {} } = data;

            const timeZone =
              user.propertiesMap.timeZone &&
              !isEmpty(user.propertiesMap.timeZone)
                ? user.propertiesMap.timeZone
                : { value: moment.tz.guess() };

            const userPropertiesMap = user.propertiesMap
              ? {
                  propertiesMap: {
                    ...user.propertiesMap,
                    timeZone,
                  },
                }
              : {
                  propertiesMap: {
                    timeZone,
                  },
                };

            const usersConsoleRoles =
              user?.userRoles?.find((role) => role.name === "console")?.roles ||
              [];

            //go through the usersConsoleRoles and get all the availableActions for all the roles and set them in an array called availableActions
            //for each role, look into user.rolesMap and get the availableActions based on the role. Put them into an array
            const userAvailableViews = usersConsoleRoles.reduce((acc, role) => {
              return [
                ...acc,
                ...Object.keys(user.rolesMap?.[role.name].availableViews), //using actions for now. Will convert to availabeleViews after testing this part
              ];
            }, []);

            const userAvailableActions = usersConsoleRoles.reduce((acc, role) => {
              return [
                ...acc,
                ...Object.keys(user.rolesMap?.[role.name].availableActions),
              ];
            }, []);

            const {
              availableActions = {},
              viewPermissions = {},
              usersConsoleRole = "",
            } = FlattenUserRoles(
              user?.userRoles?.find((role) => role.name === "console")?.roles ||
                [],
              // user type, e.g., "Asset", "Product", or "Asset/Product", is stored on the users' propertiesMap as "consoleRole"
              user.propertiesMap?.consoleRole || ""
            );

            return {
              success: true,
              token: token,
              checkSession: {
                status: "success",
                sessionValid: true,
              },
              appUserId: user.appUserId,
              organizationId: user.organizationId,
              firstName: user.firstName || "",
              lastName: user.lastName || "",
              email: user.email || "",
              appUserType: user.propertiesMap?.consoleRole,
              userAvailableViews,
              userAvailableActions,
              usersConsoleRole,
              usersConsoleRoles,
              userActions: availableActions,
              userPermissions: user.permissions || [],
              viewPermissions,
              userPropertiesMap,
            };
          } else {
            return {
              error: "Something went wrong while fetching your user session.",
            };
          }
        })
        .catch((err) => {
          console.log(err);
          return { error: err };
        });

      // data.reduxRequestId = thunkAPI.requestId;
      return data;
    }
  }
);

export const onLogin = createAsyncThunk(
  "user/onLogin",
  async (action, thunkAPI) => {
    const state = thunkAPI.getState().user;

    let { user = {}, token = "" } = action;

    const timeZone =
      user.propertiesMap.timeZone && !isEmpty(user.propertiesMap.timeZone)
        ? user.propertiesMap.timeZone
        : { value: moment.tz.guess() };

    const userPropertiesMap = user.propertiesMap
      ? {
          propertiesMap: {
            ...user.propertiesMap,
            timeZone,
          },
        }
      : {
          propertiesMap: {
            timeZone,
          },
        };

    const usersConsoleRoles =
      user?.userRoles?.find((role) => role.name === "console")?.roles || [];

    const userAvailableViews = usersConsoleRoles.reduce((acc, role) => {
      return [
        ...acc,
        ...Object.keys(user.rolesMap?.[role.name].availableViews),
      ];
    }, []);
    //perhaps refactor the above but use for loop instead of reduce

    const userAvailableActions = usersConsoleRoles.reduce((acc, role) => {
      return [
        ...acc,
        ...Object.keys(user.rolesMap?.[role.name].availableActions),
      ];
    }, []);


    const {
      availableActions = {},
      viewPermissions = {},
      usersConsoleRole = "",
    } = FlattenUserRoles(
      user.roles,
      // user type, e.g., "Asset", "Product", or "Asset/Product", is stored on the users' propertiesMap as "consoleRole"
      user.propertiesMap?.consoleRole || ""
    );

    const data = {
      ...state,
      checkSession: {
        sessionValid: true,
        status: "idle",
      },
      success: true,
      token: token,
      appUserId: user.appUserId,
      organizationId: user.organizationId,
      firstName: user.firstName || "",
      lastName: user.lastName || "",
      email: user.email || "",
      appUserType: user.propertiesMap?.consoleRole,
      userAvailableActions,
      userAvailableViews,
      usersConsoleRole,
      usersConsoleRoles,
      userActions: availableActions,
      userPermissions: user.permissions || [],
      viewPermissions,
      userPropertiesMap,
    };

    // data.reduxRequestId = thunkAPI.requestId;

    return data;
  }
);

export const updateWidgetSettingsThunk = createAsyncThunk(
  "user/updateWidgetSettingsThunk",
  async (input, thunkAPI) => {
    const { getState, extra: apiService, dispatch } = thunkAPI;

    const { widget, settings } = input;

    const state = getState().user;

    const {
      token = localStorage.getItem("token") || "",
      appUserId = "",
      userPropertiesMap = {},
    } = state;

    const results = await apiService.user
      .updateWidgetSettings({ widget, settings, token, appUserId })
      .then((res) => {
        if (res.error) {
          return dispatch({
            type: "alert/showAlert",
            payload: { type: "error", text: res.error.toString() },
          });
        } else {
          return {
            userPropertiesMap: {
              propertiesMap: {
                ...userPropertiesMap.propertiesMap,
                widgetSettings: {
                  ...(userPropertiesMap.propertiesMap.widgetSettings || {}),
                  [widget]: {
                    ...((userPropertiesMap.propertiesMap.widgetSettings &&
                      userPropertiesMap.propertiesMap.widgetSettings[widget]) ||
                      {}),
                    ...settings,
                  },
                },
              },
            },
          };
        }
      })
      .catch((err) => {
        console.log(err);
        return dispatch({
          type: "alert/showAlert",
          payload: { type: "error", text: err.toString() },
        });
      });

    return results;
  }
);
