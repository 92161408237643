const appUserTypeSelector = (state) => state.user.appUserType;
const viewPermissionsSelector = (state) => state.user.viewPermissions;
const tokenSelector = (state) => state.user.token;
const organizationIdSelector = (state) => state.user.organizationId;
const appUserIdSelector = (state) => state.user.appUserId;
const userAvailableActionsSelector = (state) => state.user.userAvailableActions;
const userAvailableViewsSelector = (state) => state.user.userAvailableViews;
const userActionsSelector = (state) => state.user.userActions;
const usersConsoleRoleSelector = (state) => state.user.usersConsoleRole;
const usersConsoleRolesSelector = (state) => state.user.usersConsoleRoles;
const userPropertiesMapSelector = (state) => state.user.userPropertiesMap;
const checkSessionSelector = (state) => state.user.checkSession;

export {
  appUserTypeSelector,
  viewPermissionsSelector,
  tokenSelector,
  organizationIdSelector,
  appUserIdSelector,
  userAvailableActionsSelector,
  userAvailableViewsSelector,
  userActionsSelector,
  usersConsoleRoleSelector,
  usersConsoleRolesSelector,
  userPropertiesMapSelector,
  checkSessionSelector,
};
