export const getDevices = async (props) => {
  const { apiUrl, organizationId, token } = props;
  let elasticQuery = {
    elasticSearchQuery: {
      bool: {
        must: [
          {
            term: {
              current_owner_id: organizationId,
            },
          },
          { terms: { asset_mode: ["Device"] } },
        ],
      },
    },
    skip: 0,
    limit: 1000,
  };
  const devices = await fetch(`${apiUrl}assets/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(elasticQuery),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
      return {
        error: "Failed to fetch Device, please contact system administrator.",
      };
    });

  return devices;
};

// This exist in the pages/devices folder.
// I want to eventually have all these calls in an API folder.
// Need to migrate at some point
// eslint-disable-next-line no-unused-vars
export async function searchDevices(props, tag = {}, start = 0, limit = 300) {
  const { apiUrl, token, organizationId } = props;

  let elasticQuery = {
    elasticSearchQuery: {
      bool: {
        must: [
          // Current owner
          {
            term: {
              current_owner_id: organizationId,
            },
          },
          // Think we can replace this with asset mode
          // but this is looking for assets with device.status
          {
            bool: {
              must: [
                {
                  nested: {
                    path: "device",
                    query: {
                      exists: {
                        field: "device.status",
                      },
                    },
                  },
                },
              ],
            },
          },
        ],
      },
    },
    limit: limit,
    sort: [
      {
        time_of_log: "asc",
      },
      {
        tag: "desc",
      },
    ],
  };

  // Tag
  if (tag) {
    elasticQuery.elasticSearchQuery.bool.must.push({
      regexp: {
        "tag.wildcard": {
          value: `${tag}.*`,
        },
      },
    });
  }

  const results = await fetch(`${apiUrl}assets/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(elasticQuery),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      console.log(elasticQuery);
      return {
        error: "Failed to fetch data, please contact system administrator.",
      };
    });

  return results;
}
