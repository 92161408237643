import {
  AppBar,
  Avatar,
  Box,
  Button,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { Info, Menu as MenuIcon } from "@mui/icons-material";
import HelpIcon from "@mui/icons-material/Help";
import AssetSelector from "../../Selects/AssetSelector/AssetSelector";
import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useTheme } from "@mui/material";

export default function TopNav(props) {
  const theme = useTheme();
  const { palette = {} } = theme;
  const { common = {}, primary = {} } = palette;
  const classes = {
    avatar: {
      backgroundColor: common?.white,
      color: primary?.main,
    },
    dropdownAvatar: {
      backgroundColor: primary?.main,
      color: common?.white,
    },
  };

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElInfo, setAnchorElInfo] = useState(null);
  const [moreEl, setMoreEl] = useState(null);

  const handleNavMenuOpen = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleUserMenuOpen = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleInfoMenuOpen = (event) => {
    setAnchorElInfo(event.currentTarget);
  };

  const handleMoreMenuOpen = (event) => {
    setMoreEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElNav(null);
    setAnchorElInfo(null);
    setAnchorElUser(null);
    setMoreEl(null);
  };

  const navLinks = [];

  const firstInitial = localStorage
    .getItem("firstName")
    ?.charAt(0)
    .toUpperCase();
  const lastInitial = localStorage.getItem("lastName")?.charAt(0).toUpperCase();
  const initials =
    firstInitial && lastInitial ? `${firstInitial}${lastInitial}` : null;

  const email = localStorage.getItem("userEmail");
  const userId = localStorage.getItem("userId");

  const versionNumber = "v2.3.12";

  return (
    <Box>
      <AppBar
        sx={{
          backgroundColor: primary?.main,
          boxShadow: "none",
          paddingLeft: "95px", // using 95 because the width of sidenav is 90.
        }}
      >
        <Toolbar>
          {/* Left Section: Navigation Links */}
          <Box
            sx={{
              alignItems: "center",
              display: { xs: "none", md: "flex" },
              flexGrow: 1,
            }}
          >
            {navLinks.map((link) => (
              <Button key={link} color="inherit" onClick={handleNavMenuOpen}>
                {link}
              </Button>
            ))}
            {/* Dropdown for "More" */}
            {navLinks.length > 0 && (
              <>
                <Button color="inherit" onClick={handleMoreMenuOpen}>
                  More
                </Button>
                <Menu
                  anchorEl={moreEl}
                  open={Boolean(moreEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>More Link 1</MenuItem>
                  <MenuItem onClick={handleClose}>More Link 2</MenuItem>
                </Menu>
              </>
            )}
          </Box>

          {/* Center Section: Search Field */}
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "flex" },
              justifyContent: "center",
              marginRight: { xs: 2, md: 0 }, // Adds margin-right on xs screens, no margin for md and above
              marginLeft: { xs: 2, md: 0 }, // Adds margin-left on xs screens, no margin for md and above
            }}
          >
            <AssetSelector {...props} />
          </Box>

          {/* Right Section: Icons */}
          <Box
            sx={{
              alignItems: "center",
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "flex-end",
            }}
          >
            <Typography
              component={"span"}
              sx={{ color: "white", paddingRight: "10px" }}
            >
              {versionNumber}
            </Typography>

            <IconButton color="inherit">
              <Tooltip title="Support" placement="bottom">
                <Link
                  href="http://helpdesk.locatorx.com/support/home"
                  rel="noopener noreferrer"
                  sx={{
                    textDecoration: "none",
                    color: "inherit",
                    "&:hover": {
                      color: "inherit",
                    },
                  }}
                  target="_blank"
                >
                  <HelpIcon />
                </Link>
              </Tooltip>
            </IconButton>

            {/* Info Icon (has menu) */}
            <IconButton color="inherit" onClick={handleInfoMenuOpen}>
              <Tooltip title="Information" placement="bottom">
                <Info />
              </Tooltip>
            </IconButton>

            {/* User Avatar/Profile (has menu) */}
            <IconButton color="inherit" onClick={handleUserMenuOpen}>
              <Tooltip title={email} placement="right">
                <Avatar sx={classes.avatar}>{initials}</Avatar>
              </Tooltip>
            </IconButton>

            {/* Menu for Information */}
            <Menu
              anchorEl={anchorElInfo}
              open={Boolean(anchorElInfo)}
              onClose={handleClose}
            >
              {/* privacy policy link */}
              <Link
                href="https://wiki.locatorx.com/privacy-policy/"
                rel="noopener noreferrer"
                sx={{ textDecoration: "none", color: "inherit" }}
                target="_blank"
              >
                <MenuItem>Privacy Policy</MenuItem>
              </Link>
              {/* terms of use link */}
              <Link
                href="https://wiki.locatorx.com/terms-of-use/"
                rel="noopener noreferrer"
                sx={{ textDecoration: "none", color: "inherit" }}
                target="_blank"
              >
                <MenuItem>Terms of Use</MenuItem>
              </Link>
            </Menu>

            {/* Menu for User Profile */}
            <Menu
              anchorEl={anchorElUser}
              open={Boolean(anchorElUser)}
              onClose={handleClose}
            >
              <RouterLink
                to={{
                  pathname: "/users",
                  state: {
                    modal: "Edit or Create User",
                    modalShow: true,
                    userId: userId,
                  },
                }}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <MenuItem>Profile</MenuItem>
              </RouterLink>
              <RouterLink
                to={{ pathname: "/logout" }}
                style={{
                  textDecoration: "none",
                  color: "inherit",
                }}
              >
                <MenuItem onClick={handleClose}>Logout</MenuItem>
              </RouterLink>
            </Menu>
          </Box>

          {/* Responsive Hamburger Menu */}
          <Box
            sx={{
              display: { xs: "flex", md: "none" },
            }}
          >
            <IconButton
              color="inherit"
              edge="start"
              onClick={handleNavMenuOpen}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorElNav}
              open={Boolean(anchorElNav)}
              onClose={handleClose}
              sx={{
                alighItems: "center",
                justifyContent: "center",
              }}
            >
              {navLinks.length > 0 && (
                <>
                  {navLinks.map((link) => (
                    <MenuItem key={link} onClick={handleClose}>
                      {link}
                    </MenuItem>
                  ))}
                  <MenuItem onClick={handleClose}>More</MenuItem>
                </>
              )}

              <RouterLink
                style={{ textDecoration: "none", color: "inherit" }}
                to={{
                  pathname: "/users",
                  state: {
                    modal: "Edit or Create User",
                    modalShow: true,
                    userId: userId,
                  },
                }}
              >
                <MenuItem>Profile</MenuItem>
              </RouterLink>

              <RouterLink
                to={{ pathname: "/logout" }}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <MenuItem onClick={handleClose}>Logout</MenuItem>
              </RouterLink>
              <Link
                href="http://helpdesk.locatorx.com/support/home"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "inherit" }}
                target="_blank"
              >
                <MenuItem>Support</MenuItem>
              </Link>
              <MenuItem divider={true} onClick={handleInfoMenuOpen}>
                Information
              </MenuItem>

              <MenuItem
                sx={{
                  cursor: "default",
                  justifyContent: "center",
                  textDecoration: "none",
                }}
              >
                {versionNumber}
              </MenuItem>
              <MenuItem
                sx={{
                  cursor: "default",
                  justifyContent: "center",
                  textDecoration: "none",
                }}
              >
                <Tooltip title={email} placement="left">
                  <Avatar sx={classes.dropdownAvatar}>{initials}</Avatar>
                </Tooltip>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
