export async function searchDeviceAssets(
  props,
  joined_device_asset_ids = "",
  limit = 300
) {
  const { apiUrl, token, organizationId } = props;
  const elasticQuery = {
    elasticSearchQuery: {
      bool: {
        must: [
          {
            term: {
              current_owner_id: organizationId,
            },
          },
          {
            terms: {
              parent_id: joined_device_asset_ids,
            },
          },
        ],
      },
    },
    sort: [
      {
        time_of_log: "asc",
      },
      {
        tag: "desc",
      },
    ],
    limit: limit,
  };

  const results = await fetch(`${apiUrl}assets/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(elasticQuery),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      console.log(elasticQuery);
      return {
        error: "Failed to fetch data, please contact system administrator.",
      };
    });

  return results;
}

export async function buildDeviceAssetsMap(props, deviceList) {
  const deviceAssetIds =
    deviceList.length > 0
      ? deviceList
          .map((d) => {
            if (d.assetId) {
              return d.assetId;
            }
            return null;
          })
          .filter((el) => el !== null)
      : null;

  if (deviceAssetIds) {
    const deviceAssetsMap = await searchDeviceAssets(
      props,
      deviceAssetIds
    ).then((res) => {
      if (res.error) {
        return { error: "Error fetching device assets." };
      }
      let deviceAssetsMap = {};
      res.assets.forEach((ass) => {
        if (!deviceAssetsMap[ass.parentId]) {
          deviceAssetsMap[ass.parentId] = [];
        }
        deviceAssetsMap[ass.parentId].push(ass);
      }, {});

      return {
        ...deviceAssetsMap,
      };
    });
    return deviceAssetsMap;
  }

  return null;
}

export const unassignDevices = async (
  props,
  selectedDevices = {},
  device_asset_map = {}
) => {
  const { apiUrl, token } = props;
  const deviceAssets = Object.keys(device_asset_map)
    .filter((d) => selectedDevices[d])
    .map((d) => {
      return {
        deviceId: d,
        associatedAssets: device_asset_map[d],
      };
    });

  const iterateAndFetch = deviceAssets.map(async (d) => {
    const unassignAssets = d.associatedAssets.map(async (ass) => {
      const unassign = await fetch(`${apiUrl}assets/${ass.assetId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
        },
        body: JSON.stringify({ parentId: null }),
      })
        .then((res) => res.json())
        .then((res) => {
          return res;
        })
        .catch((err) => {
          return {
            error: err,
          };
        });
      return unassign;
    });

    const results = await Promise.all(unassignAssets).then(
      async (completed) => {
        // if any failed, then return error message from backend
        if (completed.some((res) => res.error)) {
          return completed.find((res) => res.error);
        }
        const setStatus = await fetch(`${apiUrl}assets/${d.deviceId}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "auth-token": token,
          },
          body: JSON.stringify({ device: { status: "Unassigned" } }),
        })
          .then((res) => res.json())
          .then((res) => {
            return res;
          })
          .catch((err) => {
            return {
              error: err,
            };
          });
        return setStatus;
      }
    );
    return results;
  });

  return await Promise.all(iterateAndFetch).then((completed) => {
    // if any failures, return error message from backend
    if (completed.some((res) => res.error)) {
      return completed.find((res) => res.error);
    }
    return completed;
  });
};

export const dissociateDevices = async (props, selectedDevicesIds) => {
  const { apiUrl, token } = props;
  const iterateAndFetch = selectedDevicesIds.map(async (currentDeviceId) => {
    const results = await fetch(`${apiUrl}assets/${currentDeviceId}/action`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
      body: JSON.stringify({
        action: "dissociate",
        propertiesMap: {
          formData: [],
        },
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return {
          error: err,
        };
      });

    return results;
  });

  return await Promise.all(iterateAndFetch).then((completed) => {
    // if any failures, return error message from backend
    if (completed.some((res) => res.error)) {
      return completed.find((res) => res.error);
    }
    return completed;
  });
};
